// WhyChooseUs.js (React + JSX)

import React from "react";
import "./WhyChooseUs.css";

const WhyChooseUs = () => {
  return (
    <section className="why-choose-us">
      <h4 className="why-choose-us__subheading">
        YOUR TRUSTED PARTNER IN DIGITAL INNOVATION
      </h4>
      <h2 className="why-choose-us__heading">Why Choose Us</h2>
      <p className="why-choose-us__description">
        Choosing Michel India means choosing a partner who understands your
        business challenges and opportunities. We bring a unique blend of
        technical expertise, industry knowledge, and a customer-first approach
        to every project.
      </p>

      <div className="why-choose-us__features">
        {/* Feature 1 */}
        <div className="feature-card">
          <h3 className="feature-card__number">01</h3>
          <h4 className="feature-card__title">Our Approach</h4>
          <p className="feature-card__description">
            We work closely with our clients to understand their vision, deliver
            high-quality solutions, and provide ongoing support and innovation.
          </p>
        </div>

        {/* Feature 2 */}
        <div className="feature-card">
          <h3 className="feature-card__number">02</h3>
          <h4 className="feature-card__title">Our Values</h4>
          <p className="feature-card__description">
            Innovation, Integrity, Customer Focus, Excellence, and Collaboration
            are the core values that drive us.
          </p>
        </div>

        {/* Feature 3 */}
        <div className="feature-card">
          <h3 className="feature-card__number">03</h3>
          <h4 className="feature-card__title">Our Expertise</h4>
          <p className="feature-card__description">
            With years of experience in the industry, we have the expertise to
            deliver solutions that are not only functional but also secure,
            scalable, and future-proof.
          </p>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
