// FAQ.js (React + JSX)

import React, { useState } from "react";
import "./FAQ.css";

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null); // Close the currently active item
    } else {
      setActiveIndex(index); // Open the clicked item
    }
  };

  return (
    <section className="faq">
      <h2 className="faq__heading">Frequently Asked Questions</h2>

      <div className="faq__item">
        <div className="faq__question" onClick={() => toggleFAQ(1)}>
          <span className="faq__number">01</span>
          <span className="faq__title">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit id vene?
          </span>
          <span className="faq__toggle">{activeIndex === 1 ? "✕" : "+"}</span>
        </div>
        {activeIndex === 1 && (
          <div className="faq__answer">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit id
              venenatis pretium risus euismod dictum egestas orci netus feugiat
              ut egestas ut sagittis tincidunt phasellus elit etiam cursus orci
              in. Id sed montes.
            </p>
          </div>
        )}
      </div>

      <div className="faq__item">
        <div className="faq__question" onClick={() => toggleFAQ(2)}>
          <span className="faq__number">02</span>
          <span className="faq__title">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit id vene?
          </span>
          <span className="faq__toggle">{activeIndex === 2 ? "✕" : "+"}</span>
        </div>
        {activeIndex === 2 && (
          <div className="faq__answer">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
          </div>
        )}
      </div>

      <div className="faq__item">
        <div className="faq__question" onClick={() => toggleFAQ(3)}>
          <span className="faq__number">03</span>
          <span className="faq__title">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit id vene?
          </span>
          <span className="faq__toggle">{activeIndex === 3 ? "✕" : "+"}</span>
        </div>
        {activeIndex === 3 && (
          <div className="faq__answer">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
          </div>
        )}
      </div>

      <div className="faq__item">
        <div className="faq__question" onClick={() => toggleFAQ(4)}>
          <span className="faq__number">04</span>
          <span className="faq__title">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit id vene?
          </span>
          <span className="faq__toggle">{activeIndex === 4 ? "✕" : "+"}</span>
        </div>
        {activeIndex === 4 && (
          <div className="faq__answer">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
          </div>
        )}
      </div>
    </section>
  );
};

export default FAQ;
