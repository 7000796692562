import logo from "./logo.svg";
import "./App.css";
import NavBar from "./Pages/NavBar";
import HeroSection from "./Pages/HeroSection";
import StatsSection from "./Pages/StatsSection";
import AboutUs from "./Pages/AboutUs";
import OurProducts from "./Pages/OurProducts";
import Solutions from "./Pages/Solutions";
import Testimonial from "./Pages/Testimonial";
import WhyChooseUs from "./Pages/WhyChooseUs";
import FAQ from "./Pages/FAQ";
import CallToAction from "./Pages/CallToAction";
import Footer from "./Pages/Footer";
import '@fortawesome/fontawesome-free/css/all.min.css';

function App() {
  return (
    <div className="App">
      <NavBar />
      <HeroSection />
      <StatsSection />
      <AboutUs />
      <OurProducts />
      <Solutions />
      <Testimonial/>
      <WhyChooseUs/>
      <FAQ/>
      <CallToAction/>
      <Footer/>
    
    </div>
  );
}

export default App;
