import React from "react";
import "./Solutions.css";
import CustomSoftwareImg from "../Images/11.svg"; // Uploaded image
import MobileAppImg from "../Images/12.svg"; // Update paths as needed
import CloudSolutionsImg from "../Images/13.svg";
import AIMLImg from "../Images/13.png";
import UIDesignImg from "../Images/14.svg";

const Solutions = () => {
  return (
    <section className="solutions">
      <h3 className="main">SOLUTION</h3>
      <h2 className="solutions__title">
        Empowering Businesses with Tailored Digital Solutions
      </h2>
      <p className="solutions__subtitle">
        Michel India offers a broad spectrum of software solutions designed to meet the unique needs of businesses in various industries. Our comprehensive suite of services includes
      </p>
      <div className="solutions__container">
        {/* First row: Three items */}
        <div className="solution-item">
          <img src={CustomSoftwareImg} alt="Custom Software Development" />
          <h3>Custom Software Development</h3>
          <p>Building bespoke software tailored to your specific business requirements.</p>
        </div>

        <div className="solution-item">
          <img src={MobileAppImg} alt="Mobile App Development" />
          <h3>Mobile App Development</h3>
          <p>Creating intuitive and powerful mobile applications that engage users and enhance your brand.</p>
        </div>

        <div className="solution-item">
          <img src={CloudSolutionsImg} alt="Cloud-Based Solutions" />
          <h3>Cloud-Based Solutions</h3>
          <p>Leveraging the power of the cloud to provide scalable, secure, and efficient solutions.</p>
        </div>

        {/* Second row: Two items */}
        <div className="solution-item">
          <img src={AIMLImg} alt="AI & Machine Learning Integration" />
          <h3>AI & Machine Learning Integration</h3>
          <p>Integrating intelligent solutions that drive automation, insights, and competitive advantage.</p>
        </div>

        <div className="solution-item">
          <img src={UIDesignImg} alt="UI/UX Design and Development" />
          <h3>UI/UX Design and Development</h3>
          <p>Crafting user-centric designs that offer seamless and engaging experiences.</p>
        </div>
      </div>
    </section>
  );
};

export default Solutions;
