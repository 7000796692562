import React from 'react';
import './StatsSection.css';

const StatsSection = () => {
  return (
    <div className="stats-section">
      <h2 className="stats-section__heading">Our Results In Numbers</h2>
      <p className="stats-section__subheading">Lorem ipsum dolor sit amet consectetur adipiscing elit eget quamumto.</p>
      <div className="stats-section__cards">
        <div className="stats-section__card">
          <div className="stats-section__icon">👍</div> {/* Replace with proper icon */}
          <div className="stats-section__title">Customer satisfaction</div>
          <div className="stats-section__value">99%</div>
        </div>
        <div className="stats-section__card">
          <div className="stats-section__icon">💖</div> {/* Replace with proper icon */}
          <div className="stats-section__title">Active users</div>
          <div className="stats-section__value">24M</div>
        </div>
        <div className="stats-section__card">
          <div className="stats-section__icon">📈</div> {/* Replace with proper icon */}
          <div className="stats-section__title">Company growth</div>
          <div className="stats-section__value">100%</div>
        </div>
      </div>
    </div>
  );
}

export default StatsSection;
