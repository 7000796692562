// Testimonial.js (React + JSX)

import React from "react";
import "./Testimonial.css";

const Testimonial = () => {
  return (
    <section className="testimonial">
      <div className="testimonial__content">
        <h4 className="testimonial__subheading">CLIENT TESTIMONIAL</h4>
        <h2 className="testimonial__heading">
          Success Stories That Speak for Themselves
        </h2>
        <p className="testimonial__description">
          Lorem ipsum dolor sit amet consectetur. Ornare lectus sed non eget
          sed. Duis sed ut duis ornare cursus interdum nunc. Egestas sagittis
          turpis gravida vehicula. Vitae fermentum sed amet a vulputate arcu.
          Nunc cras ac sed ultricies diam libero eu nibh. Tempus interdum
          maecenas in id enim turpis aliquet diam feugiat. Dui tellus
          condimentum amet congue vel faucibus ut velit proin.
        </p>
        <div className="testimonial__rating">
          <span className="testimonial__star">★</span>
          <span className="testimonial__star">★</span>
          <span className="testimonial__star">★</span>
          <span className="testimonial__star">★</span>
          <span className="testimonial__star">★</span>
        </div>
      </div>

      <div className="testimonial__profiles">
        {/* Testimonial Person 1 */}
        <div className="testimonial__profile">
          <img
            src="path-to-your-person1-image.png" // Replace with actual path
            alt="Matt Cannon"
            className="testimonial__profile-image"
          />
          <div className="testimonial__profile-details">
            <h4 className="testimonial__profile-name">Matt Cannon</h4>
            <p className="testimonial__profile-role">Head of Marketing</p>
          </div>
        </div>

        {/* Testimonial Person 2 */}
        <div className="testimonial__profile">
          <img
            src="path-to-your-person2-image.png" // Replace with actual path
            alt="Person 2"
            className="testimonial__profile-image"
          />
          <div className="testimonial__profile-details">
            <h4 className="testimonial__profile-name">Matt Cannon</h4>
            <p className="testimonial__profile-role">Head of Marketing</p>
          </div>
        </div>

        {/* Testimonial Person 3 */}
        <div className="testimonial__profile">
          <img
            src="path-to-your-person3-image.png" // Replace with actual path
            alt="Person 3"
            className="testimonial__profile-image"
          />
          <div className="testimonial__profile-details">
            <h4 className="testimonial__profile-name">Matt Cannon</h4>
            <p className="testimonial__profile-role">Head of Marketing</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
